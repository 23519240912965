import React, { Component } from 'react';
import {
    DataForm, 
    DataFormCommonProps, 
    DataFormControl, 
    DataFormErrors, 
    DataFormHook,
    DataFormCompositeHook, 
    DataFormResult,
    toMoney, 
    toNumber,
    fromCamelCaseToHumanCase,
    isMoney,
    checkMoney
} from '@ivorobioff/shared';
import Preference from '../../models/Preference';
import { fixedAmount } from '../../random/utils';
import { calcExchangedAmount } from '../../services/PreferenceService';


export interface ExchangeRateAmountFormProps extends DataFormCommonProps {
    preference?: Preference;
    target: string;
    topHook?: DataFormCompositeHook
}

interface ExchangeRateAmountFormState {
    controls: DataFormControl[];
}

export class ExchangeRateAmountForm extends Component<ExchangeRateAmountFormProps, ExchangeRateAmountFormState> {

    private hook = new DataFormHook();

    constructor(props: ExchangeRateAmountFormProps) {
        super(props);

        this.state = {
            controls: this.defineControls()
        }

        if (this.props.hook) {
            this.props.hook.provider = this.createProvider()
        }

        this.props.topHook!.errorModifier = this.normalizeErrors.bind(this);
    }

    componentDidUpdate(prevProps: ExchangeRateAmountFormProps) {
        if (this.props.hook && prevProps.hook !== this.props.hook) {
            this.props.hook.provider = this.createProvider();
        }

        if (this.props.topHook !== prevProps.topHook) {
            this.props.topHook!.errorModifier = this.normalizeErrors.bind(this);
        }
    }

    private createProvider() {
        return () => {
            const provider = this.hook.provider!
            const data = provider();

            if (!data) {
                return data;
            }

            return this.normalizeData(data);
        };
    } 

    private normalizeErrors(errors: DataFormErrors, result: DataFormResult): DataFormErrors {
        const target = this.props.target;

        if (result['useExchangeRate'] && errors[target]) {
            const preference = this.props.preference!;
            const incomeCurrency = preference.incomeCurrency || 'Income Currency';
            errors['spendingAmount'] = `(${incomeCurrency}) ${errors[target]}`;
            delete errors[target];
        }

        return errors;
    }
    
    private normalizeData(data: DataFormResult): DataFormResult {
        if (data['useExchangeRate']) {

            const preference = this.props.preference!;
            const target = this.props.target;

            if (preference.roundExchangedAmount) {
                data[target] = Math.round(data['spendingAmount'] / preference.exchangeRate!);
            } else {
                data[target] = fixedAmount(data['spendingAmount'] / preference.exchangeRate!);
            }
        }
        
        return data;
    }

    private defineControls(exchangeRateEnabled: boolean = false, resultingAmount?: string): DataFormControl[] {
        const controls: DataFormControl[] = [];

        const label = fromCamelCaseToHumanCase(this.props.target);

        if (exchangeRateEnabled) {
            const preference = this.props.preference!;

            const spendingCurrency = preference.spendingCurrency || 'Spending Currency';
            const incomeCurrency = preference.incomeCurrency || 'Income Currency';

            controls.push({
                type: 'text',
                name: 'spendingAmount',
                label: `${label} (${spendingCurrency})`,
                required: true,
                validate: checkMoney,
                convertIn: toMoney,
                convertOut: toNumber,
                onInput: value => {

                    let resultingAmount = '0.00';

                    if (isMoney(value)) {
                      resultingAmount = calcExchangedAmount(value, preference);
                    }

                    this.refreshControls(exchangeRateEnabled, resultingAmount);
                }
            });

            controls.push({
                type: 'text',
                name: 'resultingAmount',
                label: `${label} (${incomeCurrency})`,
                value: resultingAmount,
                disabled: true
            });
        } else {
            controls.push({
                type: 'text',
                name: this.props.target,
                label: fromCamelCaseToHumanCase(this.props.target),
                required: true,
                validate: checkMoney,
                convertIn: toMoney,
                convertOut: toNumber
            });
        }

        const preference = this.props.preference;

        if (preference && preference.exchangeRate) {
            controls.push({
                type: 'checkbox',
                label: 'Use exchange rate: ' + preference.exchangeRate,
                name: 'useExchangeRate',
                value: exchangeRateEnabled,
                onInput: enabled => this.refreshControls(enabled)
            });
        }

        return controls;
    }

    private refreshControls(exchangeRateEnabled: boolean, resultingAmount?: string) {
        this.setState({ controls: this.defineControls(exchangeRateEnabled, resultingAmount) })
    }

    render() {
        return (<DataForm {...this.props} hook={this.hook} controls={this.state.controls} />);
    }
}