import Saving, {SavingToCreate, SavingToUpdate} from "../models/Saving";
import {Observable} from "rxjs";
import {HttpCommunicator, Container} from "@ivorobioff/shared";


export class SavingService {

    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    getAll(): Observable<Saving[]> {
        return this.http.get('/savings');
    }

    updateAmount(id: string, amount: number, note: string | undefined): Observable<any> {
        return this.http.patch('/savings/' + id, { amount, note });
    }

    update(id: string, updates: SavingToUpdate): Observable<any> {
        return this.http.patch('/savings/' + id, updates);
    }

    create(saving: SavingToCreate): Observable<Saving> {
        return this.http.post('/savings', saving);
    }

    remove(id: string): Observable<any> {
        return this.http.delete('/savings/' + id);
    }
}