import { catchError } from "rxjs/operators";
import { cloneWith } from "@ivorobioff/shared";
import { Observable, throwError } from "rxjs";

export function mapAmountError<T>(field: string): (origin: Observable<T>) => Observable<T>  {
    return catchError(error => {
        if (typeof error === 'object') {
            error = cloneWith(error, { amount: error[field] });
        }

        return throwError(error);
    });
}