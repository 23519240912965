import {HttpCommunicator, Container} from "@ivorobioff/shared";
import { Invite } from "../models/Invite";
import { Observable } from "rxjs";

class InviteService {
    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    create(): Observable<Invite> {
        return this.http.post('/invites');
    }
}

export default InviteService;