import React, { Component } from 'react';
import {
    DataForm, 
    DataFormControl, 
    DataFormResult, 
    DataFormErrors,
    toNumber, 
    toNullIfBlank,
    PopupFormComposite
} from "@ivorobioff/shared";
import {Observable} from "rxjs";
import { fixedAmount } from '../../random/utils';
import { ExchangeRateAmountForm } from '../parts/ExchangeRateAmountForm';
import { Box } from '@material-ui/core';
import Preference from '../../models/Preference';

export type AmountEditorCalculator = (amount: number) => number;

export type AmountEditorHandler = (calculator: AmountEditorCalculator, note: string | undefined) => Observable<any>;
export type AmountEditorValidator = (calculator: AmountEditorCalculator) => string | undefined;

export interface AmountEditorProps {
    open: boolean;
    onHandle: AmountEditorHandler;
    onValidate?: AmountEditorValidator;
    onClose: () => void;
    title: string;
    defaultIntent?: AmountEditorIntent;
    preference?: Preference;
}

interface AmountEditorState {
    controls: DataFormControl[]
}

export enum AmountEditorIntent {
    MINUS = 0, PLUS = 1, AS_IS = 2
}

function amountIntents(): {[name: string]: string} {
    return  {
        [AmountEditorIntent.MINUS]: 'Minus',
        [AmountEditorIntent.PLUS]: 'Plus',
        [AmountEditorIntent.AS_IS]: 'As is',
    }
}

function calculateAmount(providedAmount: number, selectedAmount: number, intent: AmountEditorIntent) {
    if (intent === AmountEditorIntent.PLUS) {
        return fixedAmount(providedAmount + selectedAmount);
    }

    if (intent === AmountEditorIntent.MINUS) {
        return fixedAmount(providedAmount - selectedAmount);
    }

    return selectedAmount;
}

function createCalculator(data: DataFormResult) {
    return (providedAmount: number) => {
        return calculateAmount(providedAmount, data['amount'], data['intent']);
    }
}

const DEFAULT_INTENT = AmountEditorIntent.MINUS;

class AmountEditor extends Component<AmountEditorProps, AmountEditorState> {

    constructor(props: AmountEditorProps) {
        super(props);

        this.state = {
            controls: this.defineControls()
        };
    }

    render() {
        const { 
            open, 
            title, 
            onClose,
            preference
        } = this.props;

        return (<PopupFormComposite onOpen={this.open.bind(this)}
                elements={[
                    {
                        type: 'form',
                        component: props => <ExchangeRateAmountForm target="amount" preference={preference}  {...props} />
                    },
                    {
                        type: 'custom',
                        component: <Box m={2} />
                    },
                    {
                        type: 'form',
                        component: props => <DataForm {...props} controls={this.state.controls } />
                    }
                ]}
                title={title}
                open={open}
                onClose={onClose}
                onSubmit={this.submit.bind(this)}
                onValidate={this.validate.bind(this)} />);
    }

    validate(data: DataFormResult): DataFormErrors {
        const validator = this.props.onValidate;

        if (validator) {

            const error = validator(createCalculator(data));

            if (error) {
                return { amount: error }
            }
        }

        return {};
    }

    open() {
        this.setState({
            controls: this.defineControls()
        });
    }

    submit(data: DataFormResult) {
        return this.props.onHandle(createCalculator(data), data['note']);
    }

    private defineControls(): DataFormControl[] {
        return [{
            type: 'select',
            name: 'intent',
            label: 'Intent',
            convertOut: toNumber,
            values: amountIntents(),
            value: this.props.defaultIntent || DEFAULT_INTENT
        }, {
            type: 'text',
            name: 'note',
            label: 'Note',
            convertOut: toNullIfBlank,
            extra: {
                multiline: true
            }
        }];
    }
}

export default AmountEditor;