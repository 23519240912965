import { Popup, Container, isBlank, cloneArrayExcept, cloneArrayWith, peek } from '@ivorobioff/shared';
import { createStyles, IconButton, InputAdornment, Switch, TextField, Theme, withStyles } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import React, { ChangeEvent, Component } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { merge, Observable } from 'rxjs';
import Account from '../../models/Account';
import User from '../../models/User';
import AccountService from '../../services/AccountService';
import UserInfo from './UserInfo';

interface SearchUserPopupProps {
    open: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    account: Account;
    container: Container;
}

interface SearchUserPopupState {
    users: User[];
    candidates: User[];
    term?: string;
    searching: boolean;
    nextCandidates: string[],
    nextUsers: string[]
}

const styles = (theme: Theme) => createStyles({

})

class SearchUserPopup extends Component<SearchUserPopupProps, SearchUserPopupState> {

    private accountService: AccountService;

    constructor(props: SearchUserPopupProps) {
        super(props);

        this.state = this.defaultState();

        this.accountService = props.container.get(AccountService);
    }

    private search() {
        if (this.state.term) {
            this.setState({ searching: true });
            this.accountService.searchCandidates(this.props.account.id, this.state.term).subscribe(candidates => {
                this.setState({ 
                    candidates: candidates.filter(candidate => !this.state.users.some(user => user.id === candidate.id)), 
                    searching: false 
                });
            }, console.error);
        }
    }

    private captureTerm(event: ChangeEvent<HTMLInputElement>) {
        let value = event.currentTarget.value;

        this.setState({
            term: isBlank(value) ? undefined : value
        });
    }

    private onOpen() {
        this.setState(this.defaultState());

        this.accountService.getUsers(this.props.account.id).subscribe(users => {
            this.setState({ users })
        }, console.error);
    }

    private onClose() {
        this.props.onClose();
    }

    private addCandidate(user: User) {
        this.setState({
            nextUsers: cloneArrayWith(this.state.nextUsers, user.id),
            users: cloneArrayWith(this.state.users, user),
            candidates: cloneArrayExcept(this.state.candidates, user)
        });
    }

    private handleUser(checked: boolean, user: User) {
        if (checked) {
            this.setState({
                nextCandidates: cloneArrayExcept(this.state.nextCandidates, user.id),
                nextUsers: cloneArrayWith(this.state.nextUsers, user.id)
            });
        } else {
            this.setState({
                nextCandidates: cloneArrayWith(this.state.nextCandidates, user.id),
                nextUsers: cloneArrayExcept(this.state.nextUsers, user.id)
            });
        }
    }

    private defaultState(): SearchUserPopupState {
        return {
            users: [],
            term: undefined,
            searching: false,
            candidates: [],
            nextCandidates: [],
            nextUsers: []
        };
    }

    private submit() {
        let idsToUnlink = this.state.nextCandidates;
        let idsToLink = this.state.nextUsers;

        let result: Observable<any>[] = [];

        if (idsToLink.length > 0) {
            result.push(this.accountService.link(this.props.account.id, idsToLink));
        }

        if (idsToUnlink.length > 0) {
            result.push(this.accountService.unlink(this.props.account.id, idsToUnlink));
        }

        return merge(...result).pipe(
            peek(() => {
                if (this.props.onSubmit) {
                    this.props.onSubmit();
                }
            }, 'after')
        );
    }

    render() {

        let canSearch = this.state.term && !this.state.searching;

        return (<Popup
            open={this.props.open}
            onOpen={this.onOpen.bind(this)}
            onClose={this.onClose.bind(this)}
            submitButtonDisabled={this.state.nextCandidates.length === 0 && this.state.nextUsers.length === 0}
            onHandle={this.submit.bind(this)}
            title={`${this.props.account.name} - Users`}>
            <TextField
                id="search-user"
                label="Search"
                variant="standard"
                onChange={this.captureTerm.bind(this)}
                fullWidth
                InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <IconButton disabled={!canSearch} edge="end" onClick={this.search.bind(this)}>
                            <SearchOutlined />
                        </IconButton>
                    </InputAdornment>)
                }}
            />

            {this.state.candidates.length > 0 && (<h3>Candidates</h3>)}

            {this.state.candidates.map(user => <UserInfo user={user} control={(
                <IconButton onClick={this.addCandidate.bind(this, user)}>
                    <AiOutlineUserAdd />
                </IconButton>
            )} />)}

            {this.state.users.length > 0 && (<h3>Users</h3>)}

            {this.state.users.map(user => <UserInfo user={user} control={(
                <Switch disabled={user.accountIds.includes(this.props.account.id) && user.accountIds.length === 1} 
                defaultChecked onChange={(e, c) => this.handleUser(c, user)} />
            )} />)}
        </Popup>);
    }
}


export default withStyles(styles)(SearchUserPopup);