import React, { useEffect, useRef } from 'react';

import { Box, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { Allocation, AllocationStub } from '../../../models/Allocation';
import AddIcon from '@material-ui/icons/Add';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import Account from '../../../models/Account';
import { checkMoney } from '@ivorobioff/shared';
import { DataMutationMode } from './enums';

export interface EditData {
  source: string;
  account: string;
  amount?: string;
  retained: boolean;
}

export interface SendData {
  allocation: string;
  amount?: string;
}


export interface ControlsProps {
  allocation: Allocation | AllocationStub;
  onDelete: () => void;
  onAdd: () => void;
  submit: boolean;
  refresh: boolean;
  onRefresh: () => void;
  accounts: Account[];
  onSubmit: (data: EditData | SendData) => void;
  onError: (hasErrors: boolean) => void;
  clearErrors?: boolean | DataMutationMode;
  onClearErrors: () => void;
  onDirty: (isDirty: boolean, forMode: DataMutationMode) => void;
  mode: DataMutationMode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2)
    },
    actions: {
      textAlign: 'right'
    }
  }),
);

function resolveAccount(accounts: Account[], allocation: Allocation | AllocationStub) {
  return accounts.length > 0
    ? ('accountId' in allocation ? allocation.accountId : accounts[0].id)
    : '';
}

export default function AllocationForm({
  allocation,
  onAdd,
  onDelete,
  submit = false,
  onSubmit,
  accounts,
  onError,
  onDirty,
  mode,
  refresh,
  onRefresh,
  clearErrors,
  onClearErrors
}: ControlsProps) {
  const classes = useStyles();

  const {
    control: editControl,
    setValue: setEditValue,
    reset: resetEdit,
    resetField: resetEditField,
    clearErrors: clearEditErrors,
    handleSubmit: handleEditSubmit
  } = useForm<EditData>({
    mode: 'onChange',
    defaultValues: {
      source: '',
      account: '',
      amount: '',
      retained: false
    }
  });

  const {
    control: sendControl,
    reset: resetSend,
    handleSubmit: handleSendSubmit,
    clearErrors: clearSendErrors
  } = useForm<SendData>({
    mode: 'onChange',
    defaultValues: {
      allocation: '',
      amount: ''
    }
  });

  const retained = useWatch({ control: editControl, name: 'retained' });

  const { isDirty: isEditDirty, errors: editErrors } = useFormState({ control: editControl });
  const { isDirty: isSendDirty, errors: sendErrors } = useFormState({ control: sendControl });

  const onDirtyRef = useRef(onDirty);
  onDirtyRef.current = onDirty;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  const onRefreshRef = useRef(onRefresh);
  onRefreshRef.current = onRefresh;

  const onClearErrorsRef = useRef(onClearErrors);
  onClearErrorsRef.current = onClearErrors;

  useEffect(() => {
    if (refresh) {

      resetSend({
        allocation: allocation.id,
        amount: 'retained' in allocation && allocation.retained ? allocation.amount!.toString() : ''
      });

      resetEdit({
        source: 'source' in allocation ? allocation.source : '',
        amount: 'amount' in allocation ? allocation.amount?.toString() || '' : '',
        account: resolveAccount(accounts, allocation),
        retained: 'retained' in allocation ? allocation.retained : false,
      });

      onRefreshRef.current();
    }
  }, [allocation, resetSend, resetEdit, refresh, accounts]);

  useEffect(() => {
    if (clearErrors) {
      if (typeof clearErrors === 'boolean' || clearErrors as DataMutationMode === DataMutationMode.Edit) {
        clearEditErrors();
      }

      if (typeof clearErrors === 'boolean' || clearErrors as DataMutationMode === DataMutationMode.Send) {
        clearSendErrors();
      }

      onClearErrorsRef.current();
    }
  }, [clearErrors, clearEditErrors, clearSendErrors]);

  useEffect(() => {
    resetEditField('account', {
      defaultValue: resolveAccount(accounts, allocation)
    });
  }, [allocation, accounts, resetEditField]);

  const hasErrors = Object.keys(editErrors).length > 0 || Object.keys(sendErrors).length > 0;

  useEffect(() => {
    onErrorRef.current(hasErrors);
  }, [hasErrors]);

  useEffect(() => {
    onDirtyRef.current(isEditDirty, DataMutationMode.Edit);
  }, [isEditDirty]);

  useEffect(() => {
    onDirtyRef.current(isSendDirty, DataMutationMode.Send);
  }, [isSendDirty]);


  useEffect(() => {
    if (submit) {
      (mode === DataMutationMode.Edit
        ? handleEditSubmit
        : handleSendSubmit)(data => onSubmitRef.current(data))();
    }
  }, [submit, handleEditSubmit, handleSendSubmit, mode]);

  return <Box display="flex" className={classes.root}>
    <Box flexGrow={1} marginRight={2}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Controller name="source"
            control={editControl}
            rules={{
              required: `It's required!`
            }}
            render={({ field, fieldState: { invalid, error } }) =>
              <TextField {...field} fullWidth label="Source" error={invalid} helperText={error?.message} />} />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="account"
            rules={{
              required: `It's required!`
            }}
            control={editControl}
            render={({ field, fieldState: { invalid, error } }) =>
              <FormControl fullWidth error={invalid}>
                <InputLabel>Account</InputLabel>
                <Select {...field}>
                  {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
                </Select>
                {invalid && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>} />
        </Grid>
        <Grid item xs={4}>
          {(mode === DataMutationMode.Edit || retained) && <Controller name="amount"
            control={editControl}
            rules={{
              required: !retained ? undefined : `It's required!`,
              validate: value => value ? checkMoney(value) : undefined
            }}
            render={({ field, fieldState: { invalid, error } }) =>
              <TextField {...field} fullWidth label="Amount" error={invalid} helperText={error?.message} />} />}

          {mode === DataMutationMode.Send && !retained && <Controller name="amount"
            control={sendControl}
            rules={{
              required: `It's required!`,
              validate: checkMoney
            }}
            render={({ field, fieldState: { invalid, error } }) =>
              <TextField {...field} fullWidth label="Amount" error={invalid} helperText={error?.message} />} />}
        </Grid>
      </Grid>
    </Box>
    <Box>
      <IconButton color={retained ? 'secondary' : 'default'} onClick={() =>
        setEditValue('retained', !retained, { shouldDirty: true })}>
        {retained && <LocalOfferIcon />}
        {!retained && <LocalOfferOutlinedIcon />}
      </IconButton>
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
      <IconButton onClick={onAdd}>
        <AddIcon />
      </IconButton>
    </Box>
  </Box >
}