import React, {Component, Fragment} from 'react';
import {
    DataPaper,
    DataViewCellTextColor,
    DataViewColumn, DataViewPaged,
    DataViewCellFormat,
    cloneWith, 
    fromCamelCaseToHumanCase, 
    ucFirst,
    Container,
    isBlank,
    sorting, 
    textFilter,
    DataFormResult,
    DataView
} from "@ivorobioff/shared";
import moment from "moment";
import {TransactionService} from "../../services/TransactionService";
import {Transaction} from "../../models/Transaction";
import { createStyles, Divider, Popover, Typography, Theme, withStyles} from '@material-ui/core';
import { tap } from 'rxjs/operators';

const styles = (theme: Theme) => createStyles({
    notePopover: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(2),
        minWidth: 150,
        maxWidth: 400
    },

    noteTitle: {
        marginBottom: theme.spacing(0.5)
    },

    noteContent: {
        paddingTop: theme.spacing(1)
    }
});

export interface TransactionViewProps {
    container: Container;
    classes: {[name: string]:string};
}

interface TransactionViewState {
    data: Transaction[];
    note?: {
        open: boolean;
        content?: string;
        anchor: HTMLElement;
    }
}

function resolveAmountColor(amount: number): DataViewCellTextColor {
    return amount >= 0 ? 'success' : 'error';
}

function resolveAmountPrefix(amount: number) {
    return amount > 0 ? `+${amount}` : amount;
}

class TransactionView extends Component<TransactionViewProps, TransactionViewState> {

    private transactionService: TransactionService;

    columns: DataViewColumn[] = [{
        name: 'type',
        pipe: ucFirst
    },{
        name: 'name',
        query: {
            controls: [
                textFilter('name')
            ]
        }
    },{
        name: 'user.name',
        title: 'User'
    },{
        name: 'amountType',
        pipe: fromCamelCaseToHumanCase
    },{
        name: 'initialAmount',
        format: DataViewCellFormat.MONEY,
    },{
        name: 'difference',
        query: {
            controls: [
                textFilter('note')
            ]
        },
        format: DataViewCellFormat.MONEY,
        pipe: resolveAmountPrefix,
        color: resolveAmountColor,
        canClick: (transaction: Transaction) => !isBlank(transaction.note),
        onClick: (transaction, context) => this.openNote(transaction, context.anchor)
    },{
        name: 'finalAmount',
        format: DataViewCellFormat.MONEY,
    }, {
        name: 'createdAt',
        title: 'Create Date',
        pipe: v => moment(v).format('DD/MM/YYYY'),
        query: {
            controls: [
                sorting('createdAt')
            ]
        }
    }];

    private paged: DataViewPaged = {
        onChange: (offset, limit, filter?: DataFormResult) => {
            return this.transactionService.getAll(offset, limit, filter).pipe(
                tap(data => {
                    this.setState({ data });
                }, error => {
                    this.setState({ data: [] });
                })
            );
        }
    };

    constructor(props: TransactionViewProps) {
        super(props);

        this.transactionService = props.container.get(TransactionService);

        this.state = {
            data: []
        }
    }

    render() {

        const { data } = this.state;
        const { classes } = this.props;

        return (<Fragment>
            <DataPaper>
            <DataView
                title="Transactions"
                data={data}
                paged={this.paged}
                columns={this.columns} />
        </DataPaper>

        {!isBlank(this.state.note?.content) && (<Popover 
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    open={!!this.state.note!.anchor} 
                                    anchorEl={this.state.note!.anchor} 
                                    onClose={this.closeNote.bind(this)}>
                            <div className={classes.notePopover}>
                                <Typography className={classes.noteTitle} component="h2" variant="subtitle2">Note</Typography>
                                <Divider />
                                <Typography className={classes.noteContent} component="p" variant="body2">{ this.state.note!.content }</Typography>
                            </div>
                        </Popover>)}

        </Fragment>);
    }
    
    closeNote() {
        this.setState({
            note: cloneWith(this.state.note, {
                open: false,
                anchor: undefined
            })
        });
    }

    openNote(transaction: Transaction, anchor: HTMLElement) {
        this.setState({
            note: {
                open: true,
                anchor,
                content: transaction.note
            }
        });
    }
}

export default withStyles(styles)(TransactionView);