import React, { ReactElement } from "react";
import { Authenticator, Container, Environment, FastLayout as QuickLayout } from "@ivorobioff/shared";
import { History } from 'history';

export interface FastLayoutProps {
  container: Container;
  children: ReactElement;
}

export default function FastLayout({ container, children }: FastLayoutProps) {

  const env: Environment = container.get('env');
  const auth = container.get(Authenticator);
  const session = auth.session || undefined;
  const history: History = container.get('history');

  return <QuickLayout site={env.site} name={env.site.name} exit={session && ({
    title: session.actor.name,
    action: () => history.push('/plans')
  })}>{children}</QuickLayout>
}