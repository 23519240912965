import React, { Component, Fragment, ReactElement } from 'react';
import { Grid, Theme, createStyles, withStyles, Box, LinearProgress, Typography, Button, IconButton, CircularProgress, Paper } from '@material-ui/core';
import AmountStat from '../common/AmountStat';
import {
    DataView,
    DataViewAction,
    DataViewColumn,
    DataViewCellFormat,
    DataPaper,
    DataActionArea,
    cloneArray,
    cloneArrayExcept,
    cloneArrayWith,
    cloneWith,
    readField,
    transferTo,
    ucFirst,
    Confirmation,
    PopupForm,
    DataForm,
    DataFormControl,
    DataFormErrors,
    DataFormResult,
    checkMoney,
    checkAll,
    checkMax,
    toMoney,
    toNumber,
    Container,
    PopupFormComposite,
    checkZeroOrPositiveInt,
    Popup
} from '@ivorobioff/shared';
import AmountEditor, { AmountEditorCalculator, AmountEditorIntent } from '../common/AmountEditor';
import { FaPiggyBank } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { MdShuffle } from 'react-icons/md';
import Plan, { PlanAmountsToUpdate, PlanToCreate, PlanToUpdate } from "../../models/Plan";
import PlanService from "../../services/PlanService";
import { BudgetService } from "../../services/BudgetService";
import Budget from "../../models/Budget";
import { tap, concatMap } from "rxjs/operators";
import { mapAmountError } from '../../mapping/operators';
import { concat, Observable } from 'rxjs';
import TermService from '../../services/TermService';
import PreferenceService from '../../services/PreferenceService';
import { fixedAmount } from '../../random/utils';
import Preference from '../../models/Preference';
import { ExchangeRateAmountForm } from '../parts/ExchangeRateAmountForm';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { UserService } from '../../services/UserService';
import User from '../../models/User';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ErrorOutline } from '@material-ui/icons';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

interface VerifiedBudgetState {
  diff: number;
  open: boolean;
}

function budgetPerfect(budget: VerifiedBudgetState) {
  return budget.diff === 0;
}

function budgetLittleLacks(budget: VerifiedBudgetState) {
  return budget.diff < 0;
}

function budgetLacks(budget: VerifiedBudgetState) {
  return budget.diff < -20;
}

function budgetExceeds(budget: VerifiedBudgetState) {
  return budget.diff > 20;
}

function budgetDiff(budget: VerifiedBudgetState) {
  return Math.abs(budget.diff);
}

function showLackingText(value: number) {
  return `The budget is lacking by ${value}`;
}

function showExcessiveText(value: number) {
  return `There is a surplus of ${value} in the budget`;
}

function sortPlans(a: Plan, b: Plan) {
    return (b.position || 0) - (a.position || 0);
}

const styles = (theme: Theme) => createStyles({

});

interface PlanProps {
    classes: { [name: string]: string };
    container: Container;
}

interface PlanState {
    remove?: {
        plan: Plan;
        open: boolean;
    },
    edit?: {
        plan: Plan;
        open: boolean;
        controls: DataFormControl[];
    },
    exchange?: {
        plan: Plan;
        open: boolean;
        fresh: boolean;
        controls: DataFormControl[];
    },
    create?: {
        open: boolean;
        topControls: DataFormControl[],
        bottomControls: DataFormControl[];
    },
    budget: Budget,
    data: Plan[],
    newTerm: {
        open: boolean;
    },
    columns: DataViewColumn[],
    preference?: Preference,
    amountEditor?: {
        open: boolean;
        title: string;
        plan: Plan;
        scenario: string,
        defaultIntent: AmountEditorIntent
    },
    user?: User,
    budgetVerifier: ReactElement,
    verifiedBudget?: VerifiedBudgetState
}

function isUniqueByName(name: string, plans: Plan[], plan?: Plan) {
    name = name.trim().toLowerCase();

    return !plans.find(p => p.name.trim().toLowerCase() === name && (!plan || plan.id !== p.id));
}

function checkUniqueByName(name: string, plans: Plan[],
    context: { plan?: Plan, field?: string } = {}): DataFormErrors {

    const plan = context.plan;
    const field = context.field || 'name';

    if (!isUniqueByName(name, plans, plan)) {
        return { [field]: 'It already exists' }
    }

    return {};
}

class PlanView extends Component<PlanProps, PlanState> {

    private planService: PlanService;
    private budgetService: BudgetService;
    private termService: TermService;
    private preferenceService: PreferenceService;

    private userService: UserService;

    private scheduledTasks: (() => void)[] = [];

    actions: DataViewAction[] = [{
        icon: <MdShuffle />,
        onClick: (plan: Plan) => {
            this.setState({
                exchange: {
                    open: true,
                    fresh: true,
                    plan,
                    controls: this.defineExchangerControls(plan)
                }
            });
        }
    }, {
        icon: <AiOutlineEdit />,
        onClick: (plan: Plan) => {
            this.setState({
                edit: {
                    open: true,
                    plan,
                    controls: this.defineEditorControls(plan)
                }
            });
        }
    }, {
        icon: <AiFillDelete />,
        onClick: (plan: Plan) => {
            this.setState({
                remove: {
                    open: true,
                    plan
                }
            });
        },
        disabled: (plan: Plan) => plan.availableAmount < plan.plannedAmount
    }];

    constructor(props: PlanProps) {
        super(props);

        this.planService = props.container.get(PlanService);
        this.budgetService = props.container.get(BudgetService);
        this.termService = props.container.get(TermService);
        this.preferenceService = props.container.get(PreferenceService);
        this.userService = props.container.get(UserService);

        this.state = {
            data: [],
            budget: {
                actualAmount: 0,
                availableAmount: 0,
                verified: false
            },
            newTerm: {
                open: false
            },
            columns: this.defineColumns(),
            budgetVerifier: this.defineBudgetNotVerified()
        };
    }

    componentDidMount() {
        this.refreshBudget();
        this.refreshPlans();

        this.preferenceService.get().subscribe(preference => {
            this.setState({
                preference,
                columns: this.defineColumns()
            });
        });

        this.userService.get().subscribe(user => {
            this.setState({ user });
        }, console.error);
    }

    componentDidUpdate() {
        this.scheduledTasks.forEach(task => task());
        this.scheduledTasks = [];
    }

    private refreshBudget() {
        this.budgetService.get().subscribe(budget => {
            this.setState({
                budget,
                budgetVerifier: budget.verified
                    ? this.defineBudgetVerified()
                    : this.defineBudgetNotVerified()
            });
        }, console.error);
    }

    private refreshPlans() {
        this.planService.getAll().subscribe(data => {
            this.setState({ data });
        }, console.error);
    }

    private verifyBudget() {
        this.setState({
            budgetVerifier: this.defineBudgetVerifying()
        });

        this.budgetService.verify().subscribe(diff => {
            this.setState({
                budgetVerifier: this.defineBudgetVerified(),
                verifiedBudget: { ...this.state.verifiedBudget, open: true, diff }
            });
        }, console.error);
    }

    render() {
        const {
            budget,
            data,
            newTerm,
            columns,
            preference,
            user,
            budgetVerifier,
            verifiedBudget
        } = this.state;

        return (<Fragment>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <AmountStat
                                icon={<FaPiggyBank />}
                                amount={budget.actualAmount}
                                title="Budget"
                                preference={preference}
                                editable={{
                                    defaultIntent: AmountEditorIntent.AS_IS,
                                    onValidate: this.validateBudget.bind(this),
                                    onHandle: this.handleBudgetChange.bind(this)
                                }}
                                action={budgetVerifier} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <AmountStat
                                icon={<GiReceiveMoney />}
                                amount={budget.availableAmount}
                                title="Balance" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Paper>
                                <Box height={100} display="flex" justifyContent="center" alignItems="center">
                                    <Button startIcon={<RotateLeftIcon />} onClick={this.openNewTermConfirmation.bind(this)} variant="text" color="default" size="large">
                                        New Term
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Alert severity="info" action={<Button color="inherit" size="small" component={Link} to={'/accounts'}>Manage</Button>}><div>The currently selected account is <strong>{user?.activeAccount?.name}</strong></div></Alert>
                </Grid>

                <Grid item>
                    <DataPaper>
                        <DataView
                            title="Plans"
                            data={data}
                            actions={this.actions}
                            columns={columns} />

                        <DataActionArea onCreate={this.openCreator.bind(this)} />
                    </DataPaper>
                </Grid>
            </Grid>

            {this.state.remove && (<Confirmation
                onClose={this.closeRemoveConfirmation.bind(this)}
                onHandle={this.handleRemoveConfirmation.bind(this)}
                confirmButtonTitle="Proceed"
                open={readField(this.state, 'remove.open')}
                title={`${readField(this.state, 'remove.plan.name')} - Delete`}>
                {`You're about to delete "${readField(this.state, 'remove.plan.name')}". Do you want to proceed?`}
            </Confirmation>)}

            {newTerm && (<Confirmation
                onClose={this.closeNewTermConfirmation.bind(this)}
                onHandle={this.handleNewTermConfirmation.bind(this)}
                confirmButtonTitle="Proceed"
                open={newTerm.open}
                title="Start a New Term">
                You're about to start a new term. This will:
                <ul>
                    <li>reset your Budget</li>
                    <li>reset your repeatable Plans to defaults</li>
                    <li>remove all your non-repeatable Plans</li>
                </ul>
                Do you want to proceed?
            </Confirmation>)}

            {verifiedBudget && (<Popup
                onClose={this.closeVerifiedBudget.bind(this)}
                submitButtonTitle={'Got it'}
                open={verifiedBudget.open}
                title="Budget Verified">
                <p>
                    {budgetLacks(verifiedBudget)
                        ? <Alert severity="error">{showLackingText(budgetDiff(verifiedBudget))}.</Alert>
                        : budgetExceeds(verifiedBudget) ? <Alert severity="warning">{showExcessiveText(budgetDiff(verifiedBudget))}.</Alert>
                            : <Alert severity="success">{budgetPerfect(verifiedBudget)
                                ? 'All is good!'
                                : `Almost good! ${budgetLittleLacks(verifiedBudget)
                                    ? showLackingText(budgetDiff(verifiedBudget))
                                    : showExcessiveText(budgetDiff(verifiedBudget))}.`}</Alert>}
                </p>
            </Popup>)}

            {this.state.edit && (<PopupForm
                onValidate={this.validateEditor.bind(this)}
                controls={readField(this.state, 'edit.controls')}
                onClose={this.closeEditor.bind(this)}
                onSubmit={this.submitEditor.bind(this)}
                open={readField(this.state, 'edit.open')}
                title={`${readField(this.state, 'edit.plan.name')} - Update`} />)}

            {this.state.create && (<PopupFormComposite
                onValidate={this.validateCreator.bind(this)}
                elements={[
                    {
                        type: 'form',
                        component: props => <DataForm {...props} controls={this.state.create!.topControls} />
                    },
                    {
                        type: 'custom',
                        component: <Box m={2} />
                    },
                    {
                        type: 'form',
                        component: props => <ExchangeRateAmountForm target="plannedAmount"
                            preference={this.state.preference}  {...props} />
                    },
                    {
                        type: 'form',
                        component: props => <DataForm {...props} controls={this.state.create!.bottomControls} />
                    }
                ]}
                onClose={this.closeCreator.bind(this)}
                onSubmit={this.submitCreator.bind(this)}
                open={readField(this.state, 'create.open')}
                title="Plan - Create" />)}

            {this.state.exchange && (<PopupForm
                onValidate={this.validateExchanger.bind(this)}
                controls={this.state.exchange!.controls}
                onClose={this.closeExchanger.bind(this)}
                onSubmit={this.submitExchanger.bind(this)}
                onTouch={this.touchExchanger.bind(this)}
                open={this.state.exchange!.open}
                fresh={this.state.exchange!.fresh}
                title={`${this.state.exchange!.plan.name} - Exchange`} />)}

            {this.state.amountEditor && (<AmountEditor
                onHandle={this.handleAmountEditor.bind(this)}
                onValidate={this.validateAmountEditor.bind(this)}
                preference={this.state.preference}
                defaultIntent={this.state.amountEditor!.defaultIntent}
                title={this.state.amountEditor!.title}
                open={this.state.amountEditor!.open}
                onClose={this.closeAmountEditor.bind(this)} />)}
        </Fragment>);
    }

    defineBudgetVerified() {
        return (<IconButton color="primary" onClick={this.verifyBudget.bind(this)}><CheckCircleOutlineIcon /></IconButton>);
    }

    defineBudgetNotVerified() {
        return (<IconButton color="secondary" onClick={this.verifyBudget.bind(this)}><ErrorOutline /></IconButton>);
    }

    defineBudgetVerifying() {
        return (<CircularProgress />);
    }

    defineColumns(): DataViewColumn[] {
        return [
            {
                name: 'name',
                component: (plan: Plan) => {
                    let progress = 100;
   
                    if (plan.plannedAmount > 0) {
                      progress = Math.round((plan.availableAmount * 100) / plan.plannedAmount);
                    }
                    
                    return (<Fragment>
                        <span>{plan.name}</span>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="textSecondary">{progress}%</Typography>
                            </Box>
                        </Box>
                    </Fragment>);
                }
            },
            {
                name: 'plannedAmount',
                format: DataViewCellFormat.MONEY,
                onClick: plan => this.openAmountEditor(plan, 'planned')
            },
            {
                name: 'availableAmount',
                format: DataViewCellFormat.MONEY,
                onClick: plan => this.openAmountEditor(plan, 'available')
            }
        ];
    }

    validateAmountEditor(calculator: AmountEditorCalculator) {
        let plan = this.state.amountEditor!.plan;
        let scenario = this.state.amountEditor!.scenario;

        if (scenario === 'planned') {
            const plannedAmount = calculator(plan.plannedAmount);

            if (plannedAmount < 0) {
                return 'Result must be greater than, or equal to 0';
            }

            const diffAmount = fixedAmount(plannedAmount - plan.plannedAmount);

            const availableAmount = fixedAmount(plan.availableAmount + diffAmount);

            return availableAmount < 0 ? 'Available Amount will be less than 0' : undefined;
        }

        return undefined;
    }

    handleAmountEditor(calculator: AmountEditorCalculator, note: string | undefined) {

        let plan = this.state.amountEditor!.plan;
        let scenario = this.state.amountEditor!.scenario;

        if (scenario === 'planned') {
            const plannedAmount = calculator(plan.plannedAmount);

            const amounts: PlanAmountsToUpdate = { plannedAmount, note };

            const diffAmount = fixedAmount(plannedAmount - plan.plannedAmount);

            amounts.availableAmount = fixedAmount(plan.availableAmount + diffAmount);

            return this.planService.updateAmounts(plan.id, amounts).pipe(
                tap(() => {

                    transferTo(amounts, plan);

                    this.setState({
                        data: cloneArray(this.state.data),
                        budget: this.recalculateAvailableBudget(diffAmount)
                    });

                }),
                mapAmountError('plannedAmount')
            )
        }

        let availableAmount = calculator(plan.availableAmount);

        const originPlannedAmount = plan.plannedAmount;

        const tasks: Observable<any>[] = [];

        if (availableAmount < 0) {
            const absAmount = Math.abs(availableAmount);
            plan.plannedAmount = fixedAmount(plan.plannedAmount + absAmount);

            tasks.push(this.planService.updateAmounts(plan.id, {
                plannedAmount: plan.plannedAmount,
                availableAmount: fixedAmount(plan.availableAmount + absAmount),
                note
            }));

            availableAmount = 0;
        }

        const amount: PlanAmountsToUpdate = { availableAmount, note };

        if (availableAmount > plan.plannedAmount) {
            amount.plannedAmount = availableAmount;
        }

        tasks.push(this.planService.updateAmounts(plan.id, amount).pipe(
            tap(() => {
                transferTo(amount, plan);
                this.setState({
                    data: cloneArray(this.state.data),
                    budget: this.recalculateAvailableBudget(fixedAmount(plan.plannedAmount - originPlannedAmount))
                });
            }),
            mapAmountError('availableAmount')
        ));

        return concat(...tasks);
    }

    openAmountEditor(plan: Plan, scenario: string) {
        this.setState({
            amountEditor: {
                open: true,
                plan,
                scenario,
                title: `${plan.name} - ${ucFirst(scenario)}`,
                defaultIntent: scenario === 'available' ? AmountEditorIntent.MINUS : AmountEditorIntent.PLUS
            }
        });
    }

    closeAmountEditor() {
        this.setState({
            amountEditor: cloneWith(this.state.amountEditor, {
                open: false
            })
        });
    }

    openNewTermConfirmation() {
        this.setState({
            newTerm: cloneWith(this.state.newTerm, { open: true })
        })
    }

    closeNewTermConfirmation() {
        this.setState({
            newTerm: cloneWith(this.state.newTerm, { open: false })
        })
    }

    handleNewTermConfirmation() {
        return this.termService.create().pipe(
            tap(() => {
                this.refreshBudget();
                this.refreshPlans();
            })
        );
    }

    closeVerifiedBudget() {
        this.setState({
            verifiedBudget: { ...this.state.verifiedBudget!, open: false }
        });
    }

    validateEditor(result: DataFormResult): DataFormErrors {
        return checkUniqueByName(
            result['name'],
            this.state.data,
            { plan: readField(this.state, 'edit.plan') });
    }

    validateCreator(result: DataFormResult): DataFormErrors {

        let errors = checkUniqueByName(result['name'], this.state.data);

        let plannedAmount = result['plannedAmount'];
        let availableAmount = result['availableAmount'];

        if (availableAmount && availableAmount > plannedAmount) {
            errors['plannedAmount'] = 'must be greater, or equal to Available Amount';
        }

        return errors;
    }

    validateExchanger(result: DataFormResult): DataFormErrors {

        const intent = result['intent'];

        if (intent === 'new-category') {
            return checkUniqueByName(result['category'], this.state.data, { field: 'category' });
        }

        return {};
    }

    closeRemoveConfirmation() {
        this.setState({
            remove: cloneWith(this.state.remove, {
                open: false
            })
        });
    }

    handleRemoveConfirmation() {

        let plan = readField<Plan>(this.state, 'remove.plan');

        return this.planService.remove(plan.id).pipe(
            tap(() => {
                this.setState({
                    data: cloneArrayExcept(this.state.data, plan),
                    budget: this.recalculateAvailableBudget(plan.plannedAmount * -1)
                });
            })
        );
    }

    validateBudget(amount: number): string | undefined {
        return amount < 0 ? 'Result must be greater than, or equal to 0' : undefined;
    }

    handleBudgetChange(amount: number, note: string | undefined) {

        let saveBudget = this.budgetService.updateActualAmount(amount, note).pipe(
            mapAmountError('actualAmount')
        );

        let retrieveBudget = this.budgetService.get().pipe(
            tap(budget => {
                this.setState({ budget });
            })
        )

        return concat(saveBudget, retrieveBudget);
    }

    closeExchanger() {
        this.setState({
            exchange: cloneWith(this.state.exchange, {
                open: false
            })
        });
    }

    submitExchanger(data: DataFormResult) {

        const plan = this.state.exchange!.plan;
        const intent = data['intent'];
        const transferAmount = data['amount'];

        if (intent === 'budget') {

            const originPlannedAmount = plan.plannedAmount;

            const amounts: PlanAmountsToUpdate = {
                plannedAmount: fixedAmount(plan.plannedAmount - transferAmount),
                availableAmount: fixedAmount(plan.availableAmount - transferAmount),
                note: 'Return to Budget'
            }

            return this.planService.updateAmounts(plan.id, amounts).pipe(
                tap(() => {
                    transferTo(amounts, plan);

                    this.setState({
                        data: cloneArray(this.state.data),
                        budget: this.recalculateAvailableBudget(fixedAmount(plan.plannedAmount - originPlannedAmount))
                    });
                })
            )
        }

        const transferToAnother = (anotherPlan: Plan, callback: () => void) => {

            const anotherAmounts: PlanAmountsToUpdate = {
                plannedAmount: fixedAmount(anotherPlan.plannedAmount + transferAmount),
                availableAmount: fixedAmount(anotherPlan.availableAmount + transferAmount),
                note: `Transfer from ${plan.name}`
            }

            const amounts: PlanAmountsToUpdate = {
                plannedAmount: fixedAmount(plan.plannedAmount - transferAmount),
                availableAmount: fixedAmount(plan.availableAmount - transferAmount),
                note: `Transfer to ${anotherPlan.name}`
            }

            return concat(
                this.planService.updateAmounts(anotherPlan.id, anotherAmounts).pipe(
                    tap(() => {
                        transferTo(anotherAmounts, anotherPlan)
                    })
                ),
                this.planService.updateAmounts(plan.id, amounts).pipe(
                    tap(() => {
                        transferTo(amounts, plan);

                        callback();
                    })
                )
            );
        }

        if (intent === 'existing-category') {
            const anotherPlan = this.state.data.find(p => p.id === data['category'])!;

            return transferToAnother(anotherPlan, () => {
                this.setState({ data: cloneArray(this.state.data) });
            });
        }

        if (intent === 'new-category') {
            return this.planService.create({
                name: data['category'],
                availableAmount: 0,
                plannedAmount: 0,
                repeat: false
            }).pipe(
                concatMap(newPlan => {
                    return transferToAnother(newPlan, () => {
                        this.setState({
                            data: cloneArrayWith(this.state.data, newPlan)
                        });
                    });
                })
            );
        }

        throw new Error(`${intent} is not supported!`);
    }

    touchExchanger() {
        this.setState({
            exchange: cloneWith(this.state.exchange, {
                fresh: false
            })
        });
    }

    closeEditor() {
        this.setState({
            edit: cloneWith(this.state.edit, {
                open: false
            })
        });
    }

    openCreator() {
        this.setState({
            create: cloneWith(this.state.create, {
                open: true,
                topControls: this.defineCreatorTopControls(),
                bottomControls: this.defineCreatorBottomControls()
            })
        });
    }

    closeCreator() {
        this.setState({
            create: cloneWith(this.state.create, {
                open: false
            })
        });
    }

    submitEditor(data: DataFormResult) {
        data['dropPosition'] = 'position' in data
            && (data['position'] === null || typeof data['position'] === 'undefined');

        let plan = readField<Plan>(this.state, 'edit.plan');

        return this.planService.update(plan.id, data as PlanToUpdate).pipe(
            tap(() => {
                transferTo(data, plan);

                if (data['dropPosition']) {
                    plan.position = undefined;
                }

                this.setState({ data: cloneArray(this.state.data).sort(sortPlans) });
            })
        );
    }

    submitCreator(data: DataFormResult) {

        let plannedAmount = data['plannedAmount'];

        if (typeof data['availableAmount'] === 'undefined') {
            data['availableAmount'] = plannedAmount;
        }

        return this.planService.create(data as PlanToCreate).pipe(
            tap(plan => {
                this.setState({
                    data: cloneArrayWith(this.state.data, plan).sort(sortPlans),
                    budget: this.recalculateAvailableBudget(plannedAmount)
                });
            })
        )
    }

    private recalculateAvailableBudget(amount: number): Budget {
        const budget = this.state.budget;

        return cloneWith(budget, {
            availableAmount: fixedAmount(budget.availableAmount - amount)
        });
    }

    private defineExchangerControls(plan: Plan, intent?: string): DataFormControl[] {
        const controls: DataFormControl[] = [{
            type: 'radio',
            name: 'intent',
            label: `Where to transfer`,
            required: true,
            values: {
                'budget': 'Budget',
                'existing-category': 'An existing category',
                'new-category': 'A new category'
            },
            onInput: (value: string) => {

                this.scheduledTasks.push(() => {
                    this.setState({
                        exchange: cloneWith(this.state.exchange, {
                            controls: this.defineExchangerControls(plan, value),
                            fresh: true
                        })
                    });
                });
            },
            value: intent
        }];

        if (intent) {
            controls.push({
                type: 'text',
                name: 'amount',
                label: 'Amount',
                value: plan.availableAmount,
                required: true,
                validate: checkAll(checkMoney, (v) => checkMax(v, plan.availableAmount)),
                convertIn: toMoney,
                convertOut: toNumber
            });
        }

        if (intent === 'budget') {
            //
        } else if (intent === 'existing-category') {

            const categories: { [id: string]: string } = {};

            this.state.data.forEach(p => {
                if (p.id !== plan.id) {
                    categories[p.id] = p.name;
                }
            });

            controls.push({
                type: 'select',
                name: 'category',
                required: true,
                label: 'Category',
                values: categories
            });
        } else if (intent === 'new-category') {
            controls.push({
                type: 'text',
                name: 'category',
                required: true,
                label: 'Category'
            });
        }

        return controls;
    }

    private defineCreatorTopControls(): DataFormControl[] {
        return [{
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true
        }];
    }

    private defineCreatorBottomControls(options: { repeat?: boolean } = {}): DataFormControl[] {

        const controls: DataFormControl[] = [];

        controls.push({
            type: 'text',
            label: 'Available Amount',
            name: 'availableAmount',
            validate: checkMoney,
            convertOut: toNumber,
            convertIn: toMoney
        });

        controls.push({
            type: 'checkbox',
            label: 'Repeat',
            name: 'repeat',
            onInput: repeat => this.refreshCreatorBottomControls(cloneWith(options, { repeat }))
        });

        const repeatControl: DataFormControl = {
            type: 'text',
            label: 'Default Amount',
            name: 'defaultAmount',
            disabled: true,
            convertIn: toMoney,
            convertOut: toNumber,
            validate: checkMoney,
        };

        if (typeof options.repeat === 'boolean') {
            repeatControl.disabled = !options.repeat;
            repeatControl.required = options.repeat;
        }

        controls.push(repeatControl);

        controls.push({
            type: 'text',
            label: 'Position',
            name: 'position',
            validate: checkZeroOrPositiveInt,
            convertOut: toNumber
        });

        return controls;
    }

    private refreshCreatorBottomControls(options: any) {
        this.setState({
            create: cloneWith(this.state.create, {
                bottomControls: this.defineCreatorBottomControls(options)
            })
        });
    }

    private defineEditorControls(plan: Plan): DataFormControl[] {
        return [{
            type: 'text',
            label: 'Name',
            name: 'name',
            value: plan.name,
            required: true
        }, {
            type: 'checkbox',
            label: 'Repeat',
            name: 'repeat',
            value: plan.repeat,
            onInput: (value: boolean) => {
                this.setState({
                    edit: cloneWith(this.state.edit, {
                        controls: ((controls) => {
                            controls[2].disabled = !value;
                            controls[2].required = value;
                            return controls;
                        })(this.defineEditorControls(plan))
                    })
                });
            }
        }, {
            type: 'text',
            label: 'Default Amount',
            name: 'defaultAmount',
            required: plan.repeat,
            disabled: !plan.repeat,
            convertIn: toMoney,
            convertOut: toNumber,
            validate: checkMoney,
            value: plan.defaultAmount
        }, {
            type: 'text',
            label: 'Position',
            name: 'position',
            validate: checkZeroOrPositiveInt,
            convertOut: toNumber,
            value: plan.position
        }];
    }
}

export default withStyles(styles)(PlanView);