import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2)
    }
  }),
);


export default function SuccessAlert({ tag, onClose }: { tag: string, onClose: () => void }) {
  const classes = useStyles();

  return <Alert className={classes.alert} onClose={onClose}>Allocations have been <strong>{tag}</strong> successfully!</Alert>
}