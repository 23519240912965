import React, { Component, ReactElement } from 'react';
import { createStyles, Theme, withStyles, Paper, Typography, Grid, Box } from "@material-ui/core";
import { readField, toMoney } from '@ivorobioff/shared';
import AmountEditor, { AmountEditorCalculator, AmountEditorIntent } from './AmountEditor';
import clsx from 'clsx';
import { Observable } from "rxjs";
import Preference from '../../models/Preference';

const styles = (theme: Theme) => createStyles({
    root: {
        height: 100,
        padding: theme.spacing(2)
    },
    info: {
        paddingTop: theme.spacing(0.5)
    },
    icon: {
        color: theme.palette.grey[500]
    },
    iconHolder: {
        textAlign: 'center',
    },
    editable: {
        borderBottomStyle: 'dotted',
        borderBottomWidth: 'thin',
        cursor: 'pointer'
    }
});

type AmountStatEditHandler = (amount: number, note: string | undefined) => Observable<any>;
type AmountStatEditValidator = (amount: number) => string | undefined;

interface AmountStatProps {
    classes: { [name: string]: string };
    amount: number;
    title: string;
    icon: ReactElement,
    editable?: {
        defaultIntent?: AmountEditorIntent,
        onHandle: AmountStatEditHandler,
        onValidate?: AmountStatEditValidator
    };
    preference?: Preference;
    action?: ReactElement
}

interface AmountStatState {
    editorOpen: boolean;
}

class AmountStat extends Component<AmountStatProps, AmountStatState> {

    constructor(props: AmountStatProps) {
        super(props);

        this.state = {
            editorOpen: false
        }
    }

    render() {
        const {
            classes,
            amount,
            title,
            icon,
            editable,
            preference,
            action
        } = this.props;

        const {
            editorOpen
        } = this.state;

        return (<Paper className={classes.root}>
            <Grid container>
                <Grid xs={5} item className={classes.iconHolder}>
                    {React.cloneElement(icon, { size: 60, className: classes.icon })}
                </Grid>
                <Grid xs={7} item>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography component="h2" variant="h6" color="primary">{title}</Typography>
                            <div className={classes.info}>
                                <Typography

                                    {
                                    ...{
                                        onClick: editable && this.showEditor.bind(this)
                                    }
                                    }

                                    className={clsx(editable && classes.editable)}
                                    component="span"
                                    variant="body1"
                                    color={amount < 0 ? 'error' : 'initial'}>
                                    {toMoney(amount)}
                                </Typography>
                            </div>
                        </Box>
                        { action && <Box>{action}</Box> }
                    </Box>

                </Grid>
            </Grid>

            <AmountEditor

                {...{
                    defaultIntent: (editable || {}).defaultIntent
                }}

                preference={preference}
                open={editorOpen}
                title={title}
                onClose={this.closeEditor.bind(this)}
                onValidate={this.validateEditor.bind(this)}
                onHandle={this.handleEditor.bind(this)}
            />
        </Paper>)
    }

    showEditor() {
        this.setState({
            editorOpen: true
        });
    }

    validateEditor(calculator: AmountEditorCalculator): string | undefined {

        const validator = this.props.editable?.onValidate;

        if (validator) {
            const amount = calculator(this.props.amount);

            return validator(amount);
        }

        return undefined;
    }

    handleEditor(calculator: AmountEditorCalculator, note: string | undefined) {

        const newAmount = calculator(this.props.amount);
        const handler = readField<AmountStatEditHandler>(this.props, 'editable.onHandle');

        return handler(newAmount, note);
    }

    closeEditor() {
        this.setState({
            editorOpen: false
        });
    }

}

export default withStyles(styles)(AmountStat);
