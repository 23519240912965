
export function fixedAmount(value: number | string): number {
    if (value == null || typeof value === 'undefined') {
        return value;
    }

    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    return parseFloat(value.toFixed(2));
}

export function isSmallScreen() {
  const width = window.innerWidth || document.documentElement.clientWidth;

  return width < 960;
}