import { 
    DataFormResult, 
    DataFormErrors, 
    isBlank, 
    checkLength 
} from "@ivorobioff/shared";

export function validateSubmittedPassword(submission: DataFormResult): DataFormErrors {
    let password = submission['password'];
    let confirmPassword = submission['confirmPassword'];

    let errors: DataFormErrors = {};

    if (!isBlank(password) && password !== confirmPassword) {
        errors['confirmPassword'] = 'must match the password';
    }
    return errors;
}

export function checkPasswordLength(value: any): string | undefined {
    return checkLength(value, 3);
}
