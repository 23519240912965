import React, { Component, Fragment } from 'react';
import {
    DataView, 
    DataViewAction, 
    DataViewColumn, 
    DataViewCellFormat,
    cloneArray,
    cloneArrayExcept,
    cloneArrayWith,
    cloneWith,
    readField,
    transferTo,
    DataPaper,
    DataActionArea,
    DataFormControl, 
    DataFormErrors,
    DataFormResult,
    Confirmation,
    PopupForm,
    checkMoney,
    toMoney, 
    toNumber,
    Container
} from "@ivorobioff/shared";
import AmountEditor, { AmountEditorCalculator, AmountEditorIntent } from "../common/AmountEditor";
import {AiFillDelete, AiOutlineEdit} from "react-icons/ai";
import Saving, {SavingToCreate, SavingToUpdate} from "../../models/Saving";
import {SavingService} from "../../services/SavingService";
import { tap } from 'rxjs/operators';

export interface SavingViewProps {
    container: Container;
}

export interface SavingViewState {
    data: Saving[];
    remove?: {
        saving: Saving;
        open: boolean;
    },
    edit?: {
        saving: Saving;
        open: boolean;
        controls: DataFormControl[];
    },
    create?: {
        open: boolean;
        controls: DataFormControl[];
    },
    amountEditor?: {
        open: boolean;
        title: string;
        saving: Saving;
    }
}

function isUniqueByName(name: string, savings: Saving[], saving?: Saving) {
    name = name.trim().toLowerCase();

    return !savings.find(s => s.name.trim().toLowerCase() === name && (!saving || saving.id !== s.id));
}

function checkUniqueByName(name: string, savings: Saving[], saving?: Saving): DataFormErrors {
    if (!isUniqueByName(name, savings, saving)) {
        return {
            name: 'It already exists'
        }
    }

    return {};
}

class SavingView extends Component<SavingViewProps, SavingViewState> {

    private savingService: SavingService;

    columns: DataViewColumn[] = [
        {
            name: 'name'
        },
        {
            name: 'amount',
            format: DataViewCellFormat.MONEY,
            onClick: saving => this.openAmountEditor(saving)
        }
    ];

    actions: DataViewAction[] = [{
        icon: <AiOutlineEdit />,
        onClick: (saving: Saving) => {
            this.setState({
                edit: {
                    open: true,
                    saving,
                    controls: this.defineEditorControls(saving)
                }
            });
        }
    }, {
        icon: <AiFillDelete />,
        onClick: (saving: Saving) => {
            this.setState({
                remove: {
                    open: true,
                    saving
                }
            });
        }
    }];

    constructor(props: SavingViewProps) {
        super(props);

        this.savingService = props.container.get(SavingService);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.savingService.getAll().subscribe(data => {
            this.setState({ data })
        }, console.error);
    }

    render() {

        const { data } = this.state;

        return (<Fragment>
            <DataPaper>
                <DataView
                    title="Savings"
                    data={data}
                    actions={this.actions}
                    columns={this.columns} />
                    <DataActionArea onCreate={this.openCreator.bind(this)} />
            </DataPaper>
            {this.state.remove && (<Confirmation
                onClose={this.closeRemoveConfirmation.bind(this)}
                onHandle={this.handleRemoveConfirmation.bind(this)}
                confirmButtonTitle="Proceed"
                open={readField(this.state, 'remove.open')}
                title={`${readField(this.state, 'remove.saving.name')} - Delete`}>
                {`You are about to delete "${readField(this.state, 'remove.saving.name')}". Do you want to proceed?`}
            </Confirmation>)}

            {this.state.edit && (<PopupForm
                onValidate={this.validateEditor.bind(this)}
                controls={readField(this.state, 'edit.controls')}
                onClose={this.closeEditor.bind(this)}
                onSubmit={this.submitEditor.bind(this)}
                open={readField(this.state, 'edit.open')}
                title={`${readField(this.state, 'edit.saving.name')} - Update`} />) }

            {this.state.create && (<PopupForm
                onValidate={this.validateCreator.bind(this)}
                controls={readField(this.state, 'create.controls')}
                onClose={this.closeCreator.bind(this)}
                onSubmit={this.submitCreator.bind(this)}
                open={readField(this.state, 'create.open')}
                title="Saving - Create" />) }
                
            {this.state.amountEditor && (<AmountEditor
                onHandle={this.handleAmountEditor.bind(this)}
                onValidate={this.validateAmountEditor.bind(this)}
                defaultIntent={AmountEditorIntent.PLUS}
                title={this.state.amountEditor!.title}
                open={this.state.amountEditor!.open}
                onClose={this.closeAmountEditor.bind(this)} />)}
        </Fragment>);
    }

    validateAmountEditor(calculator: AmountEditorCalculator) {
        let saving = this.state.amountEditor!.saving;
        return calculator(saving.amount) < 0 ? 'Result must be greater than, or equal to 0' : undefined
    }

    handleAmountEditor(calculator: AmountEditorCalculator, note: string | undefined) {

        let saving = this.state.amountEditor!.saving;
        
        let amount = calculator(saving.amount);

        return this.savingService.updateAmount(saving.id, amount, note).pipe(
            tap(() => {
                saving.amount = amount;
                this.setState({ data: cloneArray(this.state.data) });
            })
        );
    }

    openAmountEditor(saving: Saving) {
        this.setState({ 
            amountEditor: {
                open: true,
                saving,
                title:`${saving.name} - Amount`
            }
         });
    }

    closeAmountEditor() {
        this.setState({
            amountEditor: cloneWith(this.state.amountEditor, {
                open: false
            })
        });
    }

    validateEditor(result: DataFormResult): DataFormErrors {
        return checkUniqueByName(result['name'], this.state.data, readField(this.state, 'edit.saving'));
    }

    validateCreator(result: DataFormResult): DataFormErrors {
        return checkUniqueByName(result['name'], this.state.data);
    }

    closeRemoveConfirmation() {
        this.setState({
            remove: cloneWith(this.state.remove, {
                open: false
            })
        });
    }

    handleRemoveConfirmation() {

        let saving = readField<Saving>(this.state, 'remove.saving');

        return this.savingService.remove(saving.id).pipe(
            tap(() => {
                this.setState({
                    data: cloneArrayExcept(this.state.data, saving)
                });
            })
        );
    }

    closeEditor() {
        this.setState({
            edit: cloneWith(this.state.edit, {
                open: false
            })
        });
    }

    openCreator() {
        this.setState({
            create: cloneWith(this.state.create, {
                open: true,
                controls: this.defineCreatorControls()
            })
        });
    }

    closeCreator() {
        this.setState({
            create: cloneWith(this.state.create, {
                open: false
            })
        });
    }

    submitEditor(data: DataFormResult) {

        let saving = readField<Saving>(this.state, 'edit.saving');

        return this.savingService.update(saving.id, data as SavingToUpdate).pipe(
            tap(() => {
                transferTo(data, saving);
                this.setState({ data: cloneArray(this.state.data) });
            })
        );
    }

    submitCreator(data: DataFormResult) {
        return this.savingService.create(data as SavingToCreate).pipe(
            tap(saving => {
                this.setState({
                    data: cloneArrayWith(this.state.data, saving)
                });
            })
        );
    }

    private defineEditorControls(saving: Saving): DataFormControl[] {
        return [{
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            value: saving.name
        }];
    }

    private defineCreatorControls(): DataFormControl[] {
        return [{
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true
        }, {
            type: 'text',
            label: 'Amount',
            name: 'amount',
            required: true,
            validate: checkMoney,
            convertOut: toNumber,
            convertIn: toMoney
        }];
    }
}

export default SavingView;