import { AppBar, Box, Button, Container, CssBaseline, Theme, Toolbar, Typography, createStyles, withStyles } from '@material-ui/core';
import React, { ReactElement, useCallback } from 'react';
import Copyright from './Copyright';
import { FullscreenExit } from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
  appBarSpacer: theme.mixins.toolbar,
});

export interface FastLayoutProps {
  classes: Record<string, string>;
  children: ReactElement | ReactElement[];
  site: {
    url: string;
    name: string;
  },
  name: string;
  exit?: {
    action?: () => void;
    title: string;
  }
}

function FastLayout({
  classes,
  children,
  site,
  name,
  exit
}: FastLayoutProps) {

  const handleExit = useCallback(() => exit?.action?.(), [exit]);

  return <>
    <CssBaseline />
    <AppBar position="absolute">
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap>{name}</Typography>
          {exit && <Button
            aria-label={exit.title}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleExit}
            color="inherit">
            <Box marginRight={1}><Typography variant="button">{exit.title}</Typography></Box>
            <FullscreenExit />
          </Button>}
        </Box>
      </Toolbar>
    </AppBar>
    <main>
      <div className={classes.appBarSpacer} />
      {children}
      <Box pt={4}>
        <Copyright url={site.url} name={site.name} />
      </Box>
    </main>
  </>
}

export default withStyles(styles)(FastLayout);