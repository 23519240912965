import {Transaction} from "../models/Transaction";
import {Observable} from "rxjs";
import {HttpCommunicator, Container} from "@ivorobioff/shared";

export class TransactionService {

    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    getAll(offset: number, limit: number, filter?: {[name: string]: string}): Observable<Transaction[]> {
        return this.http.get('/transactions', {
           offset, limit,
           sort: 'createdAt:DESC',
           ...filter
        });
    }
}