import React, { Component } from 'react';
import {
    Container, 
    DataFormControl, 
    DataFormResult,
    AuthDataForm,
    checkEmail,
    AuthLink,
    LinkArea,
    peek
} from '@ivorobioff/shared';
import { UserService } from '../../services/UserService';
import { Observable } from 'rxjs';
import { UserToCreate } from '../../models/User';
import { validateSubmittedPassword, checkPasswordLength } from '../../validation/password-validators';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

export interface SignUpProps extends RouteComponentProps {
    container: Container;
}

interface SignUpState {

}

class SignUp extends Component<SignUpProps, SignUpState> {

    private userService: UserService;

    private accountToken?: string;

    private controls: DataFormControl[] = [{
        type: 'text',
        label: 'Name',
        name: 'name',
        required: true,
        extra: {
            variant: 'outlined'
        }
    }, {
        type: 'text',
        label: 'E-mail',
        name: 'email',
        required: true,
        validate: checkEmail,
        extra: {
            variant: 'outlined'
        }
    },{
        type: 'text',
        label: 'Password',
        name: 'password',
        validate: checkPasswordLength,
        required: true,
        extra: {
            type: 'password',
            variant: 'outlined'
        }
    },{
        type: 'text',
        label: 'Confirm Password',
        name: 'confirmPassword',
        required: true,
        extra: {
            type: 'password',
            variant: 'outlined'
        }
    }]
    
    constructor(props: SignUpProps) {
        super(props);

        const { container } = props;

        this.userService = container.get(UserService);
    }

    componentDidMount() {
        let query = this.props.location.search;

        if ((query || '').trim().length > 0) {
            let params = queryString.parse(query);
            this.accountToken = params.accountToken as string;
        }
    }

    render() {
        const links: AuthLink[] = [{
            route: '/sign-in',
            title: 'Already have an account? Sign In'
        }];

        return (<AuthDataForm 
                    onValidate={validateSubmittedPassword}
                    controls={this.controls} 
                    onSubmit={this.submit.bind(this)}
                    submitTitle="Sign Up">
                        <LinkArea links={links} />
                    </AuthDataForm>);
    }

    submit(submission: DataFormResult): Observable<any> {

        const user = submission as UserToCreate;
        
        user.activeAccountId = this.accountToken;

        return this.userService.create(user).pipe(
            peek(() => {
                this.props.history.push('/sign-in', { flash: 'You have been registered! Please sign-in using your email/password.' });
            }, 'after')
        );
    }
}

export default withRouter(SignUp);