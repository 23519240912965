import React, { Component } from 'react';
import {
  FlameLayout,
  UserMenuOptions,
  Authenticator,
  Container,
  readField,
  cloneWith,
  Environment
} from '@ivorobioff/shared';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import { QueuePlayNext } from '@material-ui/icons';
import { isSmallScreen } from '../../random/utils';
import FlipIcon from '@material-ui/icons/Flip';

export interface AppLayoutProps extends RouteComponentProps {
  container: Container;
}

interface AppLayoutState {
  userMenu: UserMenuOptions
}

class AppLayout extends Component<AppLayoutProps, AppLayoutState> {

  private authenticator: Authenticator;
  private env: Environment;
  private history: History;
  private mainMenu = {
    items: [{
      title: 'Plans',
      path: '/plans',
      icon: <EqualizerIcon />
    }, {
      title: 'Debts',
      path: '/debts',
      icon: <TrendingDownIcon />
    }, {
      title: 'Savings',
      path: '/savings',
      icon: <AttachMoneyIcon />
    }, {
      title: 'Transactions',
      path: '/transactions',
      icon: <CompareArrowsIcon />
    }, {
      title: 'Allocation',
      path: '/allocation',
      icon: <FlipIcon />
    }, ...(isSmallScreen() ? [{
      title: 'Add Expenses',
      path: '/add-expenses',
      icon: <QueuePlayNext />
    }] : [])]
  };

  constructor(props: AppLayoutProps) {
    super(props);

    let container = props.container;

    this.authenticator = container.get(Authenticator);
    this.history = container.get('history');
    this.env = container.get('env') as Environment;

    this.state = {
      userMenu: {
        title: 'Guest',
        items: [[{
          title: 'Settings',
          onClick: () => this.history.push('/settings'),
        }], [{
          title: 'Logout',
          onClick: () => this.logout(),
        }]]
      }
    }
  }

  componentDidMount() {
    let title = readField(this.authenticator.session, 'actor.name');

    this.setState({
      userMenu: cloneWith(this.state.userMenu, { title })
    })
  }

  render() {
    const { children } = this.props;
    const { userMenu } = this.state;

    return (<FlameLayout
      mainMenu={this.mainMenu}
      site={this.env.site}
      title={this.env.site.name}
      userMenu={userMenu}>{children}</FlameLayout>);
  }

  logout() {
    this.authenticator.logout();
  }
}

export default withRouter(AppLayout);
