import React from 'react';

import { Box, Button, CircularProgress } from "@material-ui/core";

export interface PrimaryActionButtonProps {
  disabled: boolean;
  doing: boolean;
  children: string;
  onClick: () => void;
}

export default function PrimaryActionButton({ disabled, doing, children, onClick }: PrimaryActionButtonProps) {
  return <Box component="div" position="relative" display="inline-block">
    <Button variant="contained"
      color="primary"
      type="submit"
      onClick={onClick}
      disabled={disabled}>{children}</Button>
    {doing && <Box
      position="absolute"
      top="0"
      marginTop={0.8}
      marginLeft={-1.3}
      left="50%"><CircularProgress size={24} /></Box>}
  </Box>
}