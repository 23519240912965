import React, { ReactElement } from 'react';

import { Box, Button, Theme, createStyles, makeStyles } from "@material-ui/core";
import { DataMutationMode } from './enums';
import PrimaryActionButton from './PrimaryActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancel: {
      marginRight: theme.spacing(1)
    },
    bar: {
      borderTop: '1px solid #e0e0e0',
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(2)
    }
  }),
);

export interface ActionBarProps {
  canSave: boolean;
  onSave: () => void;
  onCancel: () => void;
  canCancel: boolean;
  canSend: boolean;
  onSend: () => void;
  mode: DataMutationMode;
  doing: boolean;
  children?: ReactElement | ReactElement[] | null | string;
}

export default function ActionBar({
  onSave,
  onSend,
  canSave,
  canSend,
  mode,
  onCancel,
  canCancel,
  doing,
  children
}: ActionBarProps) {
  const classes = useStyles();

  return <div className={classes.bar}>
    {children}
    <Box display="flex" justifyContent="flex-end">
      {mode === DataMutationMode.Edit && <>
        <Button className={classes.cancel} variant="contained" onClick={onCancel} disabled={!canCancel}>Cancel</Button>
        <PrimaryActionButton disabled={!canSave || doing} onClick={onSave} doing={doing}>Save</PrimaryActionButton>
      </>}
      {mode === DataMutationMode.Send
        && <PrimaryActionButton disabled={!canSend || doing} onClick={onSend} doing={doing}>Send</PrimaryActionButton>}
    </Box>
  </div>;
}