import React, { Component } from 'react';
import {
    Container,
    DataFormResult, 
    DataFormControl,
    DataFormHandler,
    checkEmail, 
    checkPositiveFloat,
    toNullIfBlank, 
    toNumber,
    peek,
    cloneWith,
    Trigger,
    Environment
} from '@ivorobioff/shared';
import { Paper, Container as Area, Typography, createStyles, withStyles, Theme, Box, Button } from '@material-ui/core';
import { UserService } from '../../services/UserService';
import User, { UserToUpdate } from '../../models/User';
import InviteService from '../../services/InviteService';
import { Invite } from '../../models/Invite';
import { tap } from 'rxjs/operators';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { validateSubmittedPassword, checkPasswordLength } from '../../validation/password-validators';
import PreferenceService from '../../services/PreferenceService';
import Preference from '../../models/Preference';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(3, 4, 4, 4)
    },
    invite: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start'
    },
    inviteTrigger: {
        marginRight: theme.spacing(1)
    },
    inviteUrl: {
        width: '100%',
        padding: theme.spacing(0.8, 1),
        minHeight: theme.spacing(4.5),
    }
});

export interface SettingsProps {
    container: Container;
    classes: {[name: string]: string};
}

interface SettingsState {
    user?: User;
    profileControls: DataFormControl[];
    preferenceControls: DataFormControl[];
    invite?: Invite;
    currentTab: number;
    preference?: Preference;
}

function inviteDuration(invite: Invite): string {
    let expiresAt = moment.utc(invite.expiresAt);
    let now = moment.utc();
    
    let duration = moment.duration(expiresAt.diff(now));

    return duration.humanize(true);
}

class Settings extends Component<SettingsProps, SettingsState> {

    private userService: UserService;
    private preferenceService: PreferenceService;
    private inviteService: InviteService;
    private env: Environment;

    constructor(props: SettingsProps) {
        super(props);

        this.userService = props.container.get(UserService);
        this.inviteService = props.container.get(InviteService);
        this.preferenceService = props.container.get(PreferenceService);

        this.env = props.container.get('env');

        this.state = {
            profileControls: this.defineProfileControls(),
            preferenceControls: this.definePreferenceControls(),
            currentTab: 0
        }
    }

    componentDidMount() {
        this.userService.get().subscribe(user => {
            this.setState({ user });
        }, console.error);

        this.preferenceService.get().subscribe(preference => {
            this.setState({ preference });
        });
    }

    componentDidUpdate(prevProps: SettingsProps, prevState: SettingsState) {
        if (this.state.user !== prevState.user) {
            this.setState({
                profileControls: this.defineProfileControls() 
            })
        }

        if (this.state.preference !== prevState.preference) {
            this.setState({
                preferenceControls: this.definePreferenceControls() 
            })
        }
    }

    render() {

        const { classes } = this.props;
        const  { profileControls, preferenceControls, invite, user } = this.state;

        return (<Area maxWidth="md">
            <Paper className={classes.paper}>
                <Typography 
                    className={classes.title} 
                    component="h2" 
                    variant="h6" 
                    color="primary">Profile</Typography>

               <DataFormHandler 
                    controls={profileControls} 
                    success="Your data has been saved! You might need to re-login in order to see the changes."
                    onValidate={validateSubmittedPassword}
                    onSubmit={this.submitProfile.bind(this)}>
                    
                    </DataFormHandler>
                <br/>
                <Alert severity="info" action={<Button color="inherit" size="small" component={Link} to={'/accounts'}>Manage</Button>}><div>The currently selected account is <strong>{user?.activeAccount?.name}</strong></div></Alert>
                <br/>
                <Typography 
                    className={classes.title} 
                    component="h2" 
                    variant="h6" 
                    color="primary">Preferences</Typography>

                <DataFormHandler 
                    controls={preferenceControls} 
                    success="Your preferences have been saved!"
                    onSubmit={this.submitPreference.bind(this)}>
                    
                    </DataFormHandler>

                <Typography 
                    className={classes.title} 
                    component="h2" 
                    variant="h6" 
                    color="primary">Share</Typography>

                <Box mt={3} mb={2}>
                    <Typography component="p" variant="body1">You can share your account with other people!</Typography>
                </Box>

                { invite && (<Alert>The link will be expired {inviteDuration(invite)}!</Alert>)}

                <div className={classes.invite}>
                    <Trigger className={classes.inviteTrigger} variant="outlined" color="primary" disabled={!!invite} onHandle={this.invite.bind(this)}>Share</Trigger>
                    {(<Paper  variant="outlined" className={classes.inviteUrl}>
                    { invite && `${this.env.baseUrl}/sign-up?accountToken=${invite.token}`}
                    </Paper>)}
                </div>
            </Paper>
        </Area>);
    }

    invite() {
        return this.inviteService.create().pipe(
            tap(invite => {
                this.setState({ invite })
            })
        );
    }
    
    submitProfile(submission: DataFormResult) {
        return this.userService.update(submission as UserToUpdate).pipe(
            peek(() => {
            
                this.setState({
                    user: cloneWith(this.state.user, submission)
                });
            }, 'after')
        );
    }

    submitPreference(submission: DataFormResult) {
        return this.preferenceService.save(submission as Preference).pipe(
            peek(() => {
                this.setState({
                    preference: cloneWith(this.state.preference, submission)
                });
            }, 'after')
        );
    }

    defineProfileControls(): DataFormControl[] {

        let user = this.state?.user;

        let controls: DataFormControl[] = [{
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            value: user?.name,
            disabled: !user
        }, {
            type: 'text',
            label: 'E-mail',
            name: 'email',
            required: true,
            validate: checkEmail,
            value: user?.email,
            disabled: !user
        },{
            type: 'text',
            label: 'Password',
            name: 'password',
            validate: checkPasswordLength,
            disabled: !user,
            convertOut: toNullIfBlank,
            extra: {
                type: 'password'
            }
        },{
            type: 'text',
            label: 'Confirm Password',
            name: 'confirmPassword',
            disabled: !user,
            extra: {
                type: 'password'
            }
        }];

        return controls;
    }

    definePreferenceControls(): DataFormControl[] {

        let preference = this.state?.preference;

        return [
            {
                type: 'text',
                label: 'Income Currency',
                name: 'incomeCurrency',
                value: preference?.incomeCurrency,
            },
            {
                type: 'text',
                label: 'Spending Currency',
                name: 'spendingCurrency',
                value: preference?.spendingCurrency,
            },
            {
                type: 'text',
                name: 'exchangeRate',
                label: 'Exchange Rate',
                value: preference?.exchangeRate,
                validate: checkPositiveFloat,
                convertOut: toNumber
            },
            {
                type: 'checkbox',
                name: 'roundExchangedAmount',
                label: 'Round Exchanged Amount',
                value: preference?.roundExchangedAmount
            }
        ];
    }
}

export default withStyles(styles)(Settings);