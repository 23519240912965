import { v4 as uuid } from 'uuid';

export interface AllocationStub {
  id: string;
  _stub: true;
}

export interface Allocation {
  id: string;
  source: string;
  accountId: string;
  amount?: number;
  retained: boolean;
}

export interface UpdateAllocationData extends Omit<Allocation, 'id'> {
  
}

export interface SendAllocationData {
  allocationId: string;
  amount?: number;
}

export function createAllocationStub(): AllocationStub {
  return { id: uuid(), _stub:  true };
}

export function isAllocationStub(instance: Allocation | AllocationStub) {
  return '_stub' in instance;
}