import { Container, HttpCommunicator } from "@ivorobioff/shared";
import { Observable } from "rxjs";
import Account, { AccountToCreate, AccountToUpdate } from "../models/Account";
import User from "../models/User";

class AccountService {
    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    getAll(): Observable<Account[]> {
        return this.http.get('/accounts');
    }

    create(data: AccountToCreate): Observable<Account> {
        return this.http.post('/accounts', data);
    }

    update(id: string, updates: AccountToUpdate): Observable<any> {
        return this.http.patch(`/accounts/${id}`, updates);
    }

    remove(id: string): Observable<any> {
        return this.http.delete(`/accounts/${id}`);
    }
    
    activate(id: string): Observable<any> {
        return this.http.post(`/accounts/${id}/activate`);
    }

    getUsers(id: string): Observable<User[]> {
        return this.http.get(`/accounts/${id}/users`);
    }

    searchCandidates(id: string, term: string): Observable<User[]> {
        return this.http.get(`/accounts/${id}/candidates`, { term });
    }

    link(id: string, userIds: string[]): Observable<any> {
        return this.http.post(`/accounts/${id}/link`, userIds);
    }

    unlink(id: string, userIds: string[]): Observable<any> {
        return this.http.post(`/accounts/${id}/unlink`, userIds);
    }
}

export default AccountService;