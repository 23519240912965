import {HttpCommunicator, Container} from "@ivorobioff/shared";
import { Observable } from "rxjs";

export default class TermService {

    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    create(): Observable<any> {
        return this.http.post('/terms');
    }
}