import {
  HttpCommunicator,
  Container,
  peek,
  clone,
  toMoney,
  toNumber
} from "@ivorobioff/shared";
import { Observable, of } from "rxjs";
import Preference from "../models/Preference";

export function calcExchangedAmount(amount: string | number, preference?: Preference): string {
  if (!preference?.exchangeRate) {
    return amount.toString();
  }

  if (preference.roundExchangedAmount) {
    return toMoney(Math.round(toNumber(amount) / preference.exchangeRate));
  }

  return toMoney(toNumber(amount) / preference.exchangeRate);
}

class PreferenceService {
  private http: HttpCommunicator;

  private currentPreference?: Preference;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  save(data: Preference): Observable<any> {
    return this.http.put('/preference', data).pipe(
      peek(() => this.currentPreference = clone(data), 'before')
    );
  }

  get(accountId?: string): Observable<Preference> {

    if (accountId) {
      return this.http.get<Preference>(`/preference?${accountId && `accountId=${accountId}`}`);
    }

    if (this.currentPreference) {

      // every time we need to provide a new instance
      return of(clone(this.currentPreference));
    }

    return this.http.get<Preference>('/preference').pipe(
      peek(preference => this.currentPreference = preference, 'before')
    );
  }

  dropCache() {
    this.currentPreference = undefined;
  }
}

export default PreferenceService;