import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import {
  AuthLayout,
  LoginRoutes,
  Login,
  Container,
  Authenticator,
  HttpCommunicator,
  secretHeaderProvider,
  PrivateRoute,
  forbiddenErrorHandler,
  Environment,
  cloneWith,
} from '@ivorobioff/shared';
import AppLayout from './app/components/layout/AppLayout';
import PlanView from './app/components/page/PlanView';
import DebtView from "./app/components/page/DebtView";
import SavingView from "./app/components/page/SavingView";
import TransactionView from "./app/components/page/TransactionView";
import PlanService from './app/services/PlanService';
import { DebtService } from './app/services/DebtService';
import { SavingService } from './app/services/SavingService';
import { TransactionService } from './app/services/TransactionService';
import { BudgetService } from './app/services/BudgetService';
import { createBrowserHistory } from 'history';
import Settings from './app/components/page/Settings';
import { UserService } from './app/services/UserService';
import InviteService from './app/services/InviteService';
import SignUp from './app/components/page/SignUp';
import TermService from './app/services/TermService';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import PreferenceService from './app/services/PreferenceService';
import AccountService from './app/services/AccountService';
import AccountView from './app/components/page/AccountView';
import AddExpensesView from './app/components/page/AddExpensesView';
import FastLayout from './app/components/layout/FastLayout';
import { isSmallScreen } from './app/random/utils';
import AllocationView from './app/components/page/allocation/AllocationView';
import { AllocationService } from './app/services/AllocationService';

const container = new Container();

const site = {
  url: 'https://blin.familythings.cloud',
  name: 'Blin!'
}

container.registerFactory('env', () => {
  return cloneWith({
    site,
    baseUrl: 'https://blin.familythings.cloud',
    apiBaseUrl: 'http://localhost:8080/api/v1.0',
  }, window.__ENV__);
});

container.registerType(BudgetService);
container.registerType(PlanService);
container.registerType(DebtService);
container.registerType(SavingService);
container.registerType(TransactionService);
container.registerType(Authenticator);
container.registerType(UserService);
container.registerType(InviteService);
container.registerType(TermService);
container.registerType(PreferenceService);
container.registerType(AccountService);
container.registerType(AllocationService);

container.registerFactory('history', () => createBrowserHistory());

// normal
container.registerFactory('http', container => {
  return new HttpCommunicator({ baseUrl: container.get<Environment>('env').apiBaseUrl })
});

// secured
container.registerFactory('https', container => {
  return new HttpCommunicator({
    baseUrl: container.get<Environment>('env').apiBaseUrl,
    requestOptionsProvider: secretHeaderProvider(container.get(Authenticator)),
    errorHandler: forbiddenErrorHandler(container.get(Authenticator))
  })
});

container.get(Authenticator).watch();

const theme = createTheme({
  palette: {
    primary: {
      main: '#00897b',
    },
    secondary: orange,
  }
});

const routes: LoginRoutes = {
  signUp: '/sign-up'
};

export default function App() {
  return (<ThemeProvider theme={theme}><Router history={container.get('history')}>
    <Switch>
      <PrivateRoute container={container} exact path={[...(isSmallScreen() ? [] : ['/']), '/plans']}>
        <AppLayout container={container}>
          <PlanView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/debts">
        <AppLayout container={container}>
          <DebtView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/savings">
        <AppLayout container={container}>
          <SavingView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/transactions">
        <AppLayout container={container}>
          <TransactionView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/allocation">
        <AppLayout container={container}>
          <AllocationView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/settings">
        <AppLayout container={container}>
          <Settings container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path="/accounts">
        <AppLayout container={container}>
          <AccountView container={container} />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute container={container} exact path={[...(isSmallScreen() ? ['/'] : []), '/add-expenses']}>
        <FastLayout container={container}>
          <AddExpensesView container={container} />
        </FastLayout>
      </PrivateRoute>
      <Route exact path="/sign-in">
        <AuthLayout title="Sign-In" site={site}>
          <Login container={container} routes={routes} />
        </AuthLayout>
      </Route>
      <Route exact path="/sign-up">
        <AuthLayout title="Sign-Up" site={site}>
          <SignUp container={container} />
        </AuthLayout>
      </Route>
    </Switch>
  </Router></ThemeProvider>)
};