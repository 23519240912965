import Budget from "../models/Budget";
import {Observable} from "rxjs";
import {HttpCommunicator, Container} from "@ivorobioff/shared";

export class BudgetService {

    private http: HttpCommunicator;

    constructor(container: Container) {
        this.http = container.get('https');
    }

    public get(): Observable<Budget> {
        return this.http.get('/budget');
    }

    public updateActualAmount(amount: number, note: string | undefined): Observable<any> {
        return this.http.patch('/budget', { actualAmount: amount, note });
    }

    public verify(): Observable<number> {
        return this.http.post('/budget/verify');
    }
}